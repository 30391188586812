import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class VsTopSides extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Messi and Ronaldo vs Top 3, Top 5 etc. - Messi and Ronaldo Goals and Stats vs Best Teams"}
          description={`How have Messi and Ronaldo performed against the top 10 compared to the bottom 10? What about against top 3 sides? Compare their goals, assists, hat-tricks and more.`}
          canonicalPath={`/all-time-stats/vs-top-sides/`}
        />

        <h1>
          <span className="sr-only">Messi and Ronaldo Goals and Stats</span> vs Best Teams
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'All', 'url': 'none', 'active': true },
            { 'name': 'vs Top 3', 'url': '/all-time-stats/vs-top-3/' },
            { 'name': 'vs Top 5', 'url': '/all-time-stats/vs-top-5/' },
            { 'name': 'vs Top 10', 'url': '/all-time-stats/vs-top-10/' },
            { 'name': 'vs Bottom 10', 'url': '/all-time-stats/vs-bottom-10/' },
            { 'name': 'vs Bottom 3', 'url': '/all-time-stats/vs-bottom-3/' }
          ]}
        />

        <CompetitionBlock competition="vs Top 3" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Top 4" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Top 5" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Top 6" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Top 10" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Bottom 10" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Bottom 5" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <CompetitionBlock competition="vs Bottom 3" type="all" subtitle="All time league" modalSubTitle="All Time League Games" mdata={mdata} rdata={rdata} perfmsg="Since 2009/10" />

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/vs-top-sides/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/vs-top-sides/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
  }
`

export default VsTopSides
